export const IGServer = [
  {
    func: "底图服务",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "目录服务",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "要素服务",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "工作流服务",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "几何空间分析服务",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "地图服务",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "专题图服务",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
];

export const ClientView = [
  {
    func: "聚类图",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "热力图",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "密度图",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "D3",
    leaflet: "",
    mapboxgl: "",
    openlayers: "",
    cesium: "",
  },
  {
    func: "点CSS样式",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "属性CSS样式",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "图层CSS样式",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "蜂窝密度图",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "统计专题图",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "边界专题图",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "动态专题图",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "交互操作",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "Echarts 4.0",
    leaflet: "",
    mapboxgl: "",
    openlayers: "",
    cesium: "",
  },
  {
    func: "点、线动画",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "点，线，区交互",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "海量点绘制-1000万点",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "海量线绘制-100万线",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "Echarts GL 1.0",
    leaflet: "",
    mapboxgl: "",
    openlayers: "",
    cesium: "",
  },
  {
    func: "出租车轨迹",
    leaflet: "不支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "三维柱状图",
    leaflet: "不支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "三维建筑图",
    leaflet: "不支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "航线图",
    leaflet: "不支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "微博签到图",
    leaflet: "不支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "城市夜景",
    leaflet: "不支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "MapV 2.0",
    leaflet: "",
    mapboxgl: "",
    openlayers: "",
    cesium: "",
  },
  {
    func: "点、线动画",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "汇聚效果",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "迁移效果",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "交通轨迹",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "单值、多值、热力统计线",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "蜂窝、网格、热力统计图",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
];

export const ClientAnalysis = [
  {
    func: "插值分析",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "缓冲区分析",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "Tin分析",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "泰森多边形分析",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "中心点提取",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
];

export const ClientTheme = [
  {
    func: "统计专题图",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "边界专题图",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "动态专题图",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "交互操作",
    leaflet: "支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "单值、多值、热力统计线",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "蜂窝、网格、热力统计图",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
];

export const VectorTile = [
  {
    func: "Mapbox MVT 矢量瓦片-不带符号",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "不支持",
  },
  {
    func: "Mapbox MVT 矢量瓦片-带行业符号",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "支持",
    cesium: "支持",
  },
  {
    func: "Geojson VectorTile",
    leaflet: "不支持",
    mapboxgl: "不支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
];

export const ElasticSearch = [
  {
    func: "地理围栏",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "时空查询功能",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "时空聚合统计功能",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
  {
    func: "时空聚合查询功能（GeoHash聚合）",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
];

export const Spark = [
  {
    func: "密度图",
    leaflet: "支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "不支持",
  },
  {
    func: "时空立方体",
    leaflet: "不支持",
    mapboxgl: "支持",
    openlayers: "不支持",
    cesium: "支持",
  },
];
